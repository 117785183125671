import React, { useEffect, useState } from 'react';
import OrganizationView from './OrganizationView';
import ReactDOMServer from 'react-dom/server';

function App() {
  const [articles, setArticles] = useState([]);
  const [titles, setTitles] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [expandedTitleIndex, setExpandedTitleIndex] = useState(0);

  useEffect(() => {
    fetch('https://api.minseoke.com/info/recent/stein/articles?k=50')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setArticles(data.data.items))
      .catch(error => console.error('Error fetching the JSON:', error));
  }, []);

  useEffect(() => {
    fetch('https://api.minseoke.com/info/recent/stein/titles?k=50')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setTitles(data.data.items))
      .catch(error => console.error('Error fetching the JSON:', error));
  }, []);

  return (
    <div style={{ padding: '50px 20px' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
        position: 'fixed',
        top: '0',
        width: '100%',
        backgroundColor: 'white',
        zIndex: '1000',
        padding: '20px 0'
      }}>
        <button onClick={() => setActiveTab(0)} style={{ marginRight: '10px', padding: '20px', cursor: 'pointer', backgroundColor: activeTab === 0 ? '#007bff' : '#ccc', color: 'white', border: 'none', borderRadius: '5px' }}>탭 1</button>
        <button onClick={() => setActiveTab(1)} style={{ padding: '20px', cursor: 'pointer', backgroundColor: activeTab === 1 ? '#007bff' : '#ccc', color: 'white', border: 'none', borderRadius: '5px' }}>탭 2</button>
      </div>
      <div style={{ paddingTop: '100px' }}>
        {activeTab === 0 && (
          <div style={{ display: 'flex', overflowX: 'auto' }}>
            <div style={{ flex: '0 0 auto', width: '100%' }}>
              {articles.map((article, index) => {
                let preEndIndex = 0;
                let offsets = article.offsets;
                let sortedOffsets = [...offsets].sort((a, b) => a.offsetBegin - b.offsetBegin);

                return (
                  <div key={index}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '10px',
                        cursor: 'pointer'
                      }}
                      onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <img src={article.thumbnail} alt="thumbnail" style={{ width: '100px', height: '100px', marginRight: '10px', borderRadius: '7px' }} />
                          <button
                            style={{
                              position: 'absolute',
                              bottom: '5px',
                              right: '5px',
                              padding: '2px 5px',
                              fontSize: '10px',
                              borderRadius: '3px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              color: 'white',
                              border: 'none',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              const img = new Image();
                              img.src = article.thumbnail;
                              const w = window.open("");
                              w.document.write(img.outerHTML);
                            }}
                          >
                            크게 보기
                          </button>
                        </div>
                        <div>
                          <h3 style={{ margin: 0, marginBottom: '1px' }}>{article.main_title}</h3>
                          <p style={{ fontSize: '15px', marginBottom: '1px' }}>카테고리: {article.selected_category} / 타입: {article.article_type}</p>
                          <p style={{ fontSize: '12px', marginBottom: '0px' }}>{article.created_at.split(':')[0]}시 / {new URL(article.source_url).origin}</p>
                        </div>
                      </div>

                      <span style={{ textDecoration: 'underline' }}>{expandedIndex === index ? '접기' : '더보기'}</span>
                    </div>

                    {expandedIndex === index && article.tldr && (
                      <div style={{
                        backgroundColor: '#f8f9fa',
                        border: '1px solid #dee2e6',
                        borderRadius: '8px',
                        padding: '15px',
                        margin: '15px 0'
                      }}>
                        {article.tldr}
                        {article.sample_questions && article.sample_questions.length > 0 && (
                          <div style={{ marginTop: '10px' }}>
                            <h4>샘플 질문:</h4>
                            <ul>
                              {article.sample_questions.map((question, qIndex) => (
                                <li key={qIndex} style={{ marginLeft: '-15px', fontSize: '14px' }}>{question}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}

                    {expandedIndex === index && article.paragraphs.map((paragraph, pIndex) => (
                      <div key={pIndex}>
                        <h3>{paragraph.sub_title}</h3>
                        <p>
                          {(() => {
                            let content = paragraph.contents;
                            let newContent = "";
                            let currentIndex = 0;
                            let newAfter = "";
                            // First apply entity highlights

                            sortedOffsets.forEach(offset => {
                              if (offset.offsetEnd < preEndIndex) {
                                return;
                              }

                              const entity = article.entities.find(e => e.eid === offset.eid);
                              if (entity) {
                                const before = content.slice(currentIndex, offset.offsetBegin - preEndIndex);
                                const highlight = content.slice(offset.offsetBegin - preEndIndex, offset.offsetEnd - preEndIndex);
                                const after = content.slice(offset.offsetEnd - preEndIndex);

                                currentIndex = offset.offsetEnd - preEndIndex;

                                newContent += before +
                                  `<a href="${entity.diffbotUri}" target="_blank" rel="noopener noreferrer"
                                          style="background-color: #fff3cd; text-decoration: none; color: inherit;">
                                        ${highlight}
                                      </a>`

                                newAfter = after;
                              }
                            });
                            if (newContent.length > 0) {
                              content = newContent + newAfter;
                            } else {
                              content = paragraph.contents;
                            }

                            preEndIndex += paragraph.contents.length;

                            // Then handle references
                            let refCount = 0;
                            const parts = content.split(/\[\]\(([^)]+)\)/);

                            return parts.map((part, index) => {
                              if (index % 2 === 0) {
                                return <span key={index} dangerouslySetInnerHTML={{ __html: part.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') }} />;
                              } else {
                                const reference = paragraph.references.find(ref => ref.id === part);
                                refCount++;
                                return reference ? (
                                  <a
                                    key={index}
                                    href={reference.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: 'none',
                                      color: '#666',
                                      backgroundColor: '#f0f0f0',
                                      borderRadius: '50%',
                                      width: '20px',
                                      height: '20px',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '12px',
                                      marginLeft: '2px',
                                      marginRight: '2px'
                                    }}
                                  >
                                    {refCount}
                                  </a>
                                ) : part;
                              }
                            });
                          })()}
                        </p>
                      </div>
                    ))}
                    <hr style={{ margin: '30px 0', border: '1px solid #eee' }} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div style={{ flex: '0 0 auto', width: '100%' }}>
            <ul>
              {titles.map((title, index) => (
                <li key={index}>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setExpandedTitleIndex(expandedTitleIndex === index ? null : index)}
                  >
                    <h3>{title.k_title}</h3>
                    <a href={title.url} target="_blank" rel="noopener noreferrer">
                      {title.title}
                    </a>
                    {expandedTitleIndex === index && <p>{title.summary}</p>}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;